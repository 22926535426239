@import url("https://fonts.googleapis.com/css?family=Noto+Sans+TC&display=swap");



@font-face {
	font-family: 'SF Pro Display';
	font-weight: 300;
	src: local('SF Pro Display'), url(./resources/font/FontsFree-Net-SFProDisplay-Light.ttf) format('opentype');
}
@font-face {
	font-family: 'SF Pro Display';
	font-weight: 500;
	src: local('SF Pro Display'), url(./resources/font/FontsFree-Net-SFProDisplay-Regular.ttf) format('opentype');
}
@font-face {
	font-family: 'SF Pro Display';
	font-weight: 700;
	src: local('SF Pro Display'), url(./resources/font/FontsFree-Net-SFProDisplay-Medium.ttf) format('opentype');
}
@font-face {
	font-family: 'SF Pro Display';
	font-weight: 900;
	src: local('SF Pro Display'), url(./resources/font/FontsFree-Net-SFProDisplay-Black.ttf) format('opentype');
}


body {
  margin: 0;
  font-family: "Noto Sans TC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body {
  height: 100%;
  margin: 0;
}
